import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import bookmarks from "../bookmarks.json"
import Search from "./Search"

export default () => {
  const [path, setPath] = useState()

  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  return (
    <header className="header-grid">
      <div className="logo">
        <Link to="/">
          Design
          <span />
          Bookmarks
        </Link>
      </div>

      <nav>
        {bookmarks.map((item, i) => (
          <Link
            key={i}
            className={"link" + (path === "/" && i === 0 ? " active" : "")}
            activeClassName="active"
            to={"/" + item.CategoryName.replace(/ /g, "-").toLowerCase()}
            onClick={() => window.scrollTo({ top: 0 })}
          >
            {item.CategoryName}
          </Link>
        ))}

        <Search data={bookmarks} />

        <div className="flex1" />
      </nav>
    </header>
  )
}
