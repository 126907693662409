import React from "react"

export default () => {
  return (
    <footer className="header-grid">
      <div>This site will be in beta for a long time.</div>

      <div>
        Curated by{" "}
        <a
          href="https://twitter.com/lincidesign"
          target="_blank"
          rel="noopener noreferrer"
        >
          @Linci
        </a>
      </div>
    </footer>
  )
}
