import React from "react"

export default ({ active, data }) => {
  const title = data.title
  const subtitle = data.subtitle
  const url = data.url
  const newURL = new URL(url)
  const screenshot = data.screenshot

  return (
    <article className="search-item">
      <a
        href={url}
        className={active ? " active" : ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        <picture>
          <source
            type="image/webp"
            srcSet={screenshot.replace(RegExp(".png$"), ".webp")}
            loading="lazy"
          />
          <source type="image/jpeg" srcSet={screenshot} loading="lazy" />

          <img src={screenshot} alt={title} loading="lazy" />
        </picture>

        <div className="content">
          <h3>
            {title}
            {subtitle && <p className="subtitle">{subtitle}</p>}
            {active && <ReturnIcon />}
          </h3>

          <p className="url">
            {newURL.hostname + newURL.pathname.replace(/\/$/, "")}
          </p>
        </div>
      </a>
    </article>
  )
}

const ReturnIcon = () => (
  <svg
    className="return-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M10 9l-6 6 6 6" />
    <path d="M20 4v7a4 4 0 0 1-4 4H5" />
  </svg>
)
