import React, { useState, useEffect } from "react"
import { motion, useSpring, AnimatePresence } from "framer-motion"
import Bookmark from "../components/Bookmark"
import Layout from "../components/Layout"

const motionContainer = {
  initial: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.075,
      delayChildren: 0.25,
    },
  },
}

const motionItem = {
  initial: { opacity: 0, y: -4 },
  show: { opacity: 1, y: 0 },
}

export default ({ customData, pageContext }) => {
  const [showAllTag, setShowAllTag] = useState(false)

  const newdata = customData || pageContext.data
  const listName = newdata.CategoryName
  const listContent = newdata.SubCategory

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.pageYOffset > 300 ? setScrolled(true) : setScrolled(false)
    )
    return window.removeEventListener("scroll", null)
  }, [])

  // Scroll smooth
  const spring = useSpring(0, { damping: 300, stiffness: 200 })

  useEffect(() => {
    spring.onChange(latest => {
      window.scrollTo(0, latest)
    })
  }, [spring])

  function moveById(id) {
    spring.set(window.pageYOffset, false)

    setTimeout(() => {
      spring.set(document.getElementById(id).offsetTop)
    }, 50)
  }

  return (
    <Layout title={listName}>
      <AnimatePresence>
        {scrolled && (
          <div className="back-to-top-container">
            <motion.div
              className="back-to-top"
              initial={{ opacity: 0, y: "-0.5em" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: "-0.5em" }}
              onClick={() => moveById("___gatsby")}
            >
              Back to top
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <div className="header-grid category-meta">
        <motion.div
          variants={motionContainer}
          initial="initial"
          animate="show"
          className="sub-category-link"
        >
          {listContent.slice(0, 9).map((item, i) => (
            <motion.div
              key={item.SubCategoryName}
              variants={motionItem}
              className="tag"
              onClick={() => moveById(item.SubCategoryName)}
            >
              {item.SubCategoryName}
              <span className="count">{item.Bookmarks.length}</span>
            </motion.div>
          ))}

          {listContent.length > 10 &&
            showAllTag &&
            listContent.slice(9).map((item, i) => (
              <motion.div
                key={item.SubCategoryName}
                variants={motionItem}
                className="tag"
                onClick={() => moveById(item.SubCategoryName)}
              >
                {item.SubCategoryName}
                <span className="count">{item.Bookmarks.length}</span>
              </motion.div>
            ))}

          {listContent.length > 10 && (
            <motion.div
              key="less-more"
              variants={motionItem}
              className="tag less-more"
              onClick={() => setShowAllTag(!showAllTag)}
            >
              {showAllTag ? "- Less" : "+ More"}
            </motion.div>
          )}
        </motion.div>
      </div>

      <main>
        {listContent.map((subCategoryData, i) => {
          const subCategoryName = subCategoryData.SubCategoryName
          const bookmarks = subCategoryData.Bookmarks

          return (
            <div key={i} className="sub-category" id={subCategoryName}>
              <h3 className="sub-category-name ">
                <span>{subCategoryName}</span>
                <span className="count">{bookmarks.length}</span>
              </h3>
              <div className="list-grid">
                {bookmarks.map((item, i) => (
                  <Bookmark key={i} bookmark={item} />
                ))}
              </div>
            </div>
          )
        })}
      </main>
    </Layout>
  )
}
