import React from "react"
import { Helmet } from "react-helmet"

import Header from "./Header"

import "normalize.css"
import "./reset.scss"
import "./styles.scss"
import Footer from "./Footer"

export default ({ title, children }) => {
  const newTitle = (title && title + " - ") + "Design Bookmarks"

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        title={newTitle}
        meta={[
          {
            property: `og:title`,
            content: newTitle,
          },
          {
            property: `og:url`,
            content: `https://bookmarks.linci.co`,
          },
          {
            property: `og:description`,
            content: `A curated list of design.`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `https://bookmarks.linci.co/summary.png`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: `@lincidesign`,
          },
        ]}
      />

      <Header />

      <main>{children}</main>

      <Footer />
    </>
  )
}
