import React, { useState, useEffect } from "react"
import { AnimatePresence, motion } from "framer-motion"
import SearchItem from "./SearchItem"
import Fuse from "fuse.js"
import { useKeyPress } from "./hooks"

export default ({ data }) => {
  const [open, setOpen] = useState(false)
  const [keywords, setKeywords] = useState("")
  const [results, setResults] = useState()
  const [currentResultPage, setCurrentResultPage] = useState(1)
  const resultsPageSize = 5

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [open])

  // flat data for search
  const flatData = data.flatMap(obj =>
    obj.SubCategory.flatMap(object => object.Bookmarks)
  )

  // add search index
  const search = new Fuse(flatData, {
    keys: ["title", "subtitle", "url"],
    minMatchCharLength: 2,
  })

  useEffect(() => {
    if (keywords) {
      setResults(search.search(keywords))
      // console.log(search.search(keywords))
    } else {
      setResults(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords])

  // Keyboard events
  const [cursor, setCursor] = useState(0)

  const escPress = useKeyPress("Escape")
  const downPress = useKeyPress("ArrowDown")
  const upPress = useKeyPress("ArrowUp")
  const enterPress = useKeyPress("Enter")

  // 通过 keywords 状态控制 reset
  useEffect(() => {
    setCursor(0)
    setCurrentResultPage(1)
    document.getElementById("results") &&
      document.getElementById("results").scrollTo(0, 0)
  }, [keywords])

  useEffect(() => {
    if (escPress) {
      setOpen(!open)
      setKeywords("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escPress])

  useEffect(() => {
    results &&
      downPress &&
      setCursor(prevState =>
        prevState < currentResultPage * resultsPageSize - 1 &&
        prevState < results.length - 1
          ? prevState + 1
          : prevState
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress])

  useEffect(() => {
    results &&
      upPress &&
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress])

  useEffect(() => {
    results && enterPress && (window.location.href = results[cursor].item.url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPress])

  return (
    <>
      <div
        className="search-entry icon"
        onClick={() => setOpen(true)}
        onKeyPress={() => setOpen(true)}
        role="button"
        tabIndex="0"
      >
        <SearchIcon />
      </div>

      <AnimatePresence>
        {open && (
          <div className="modal-container">
            <motion.section
              className="search"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <div className="input-container">
                <input
                  id="search"
                  type="text"
                  aria-label="Search"
                  spellCheck={false}
                  value={keywords}
                  placeholder="Search..."
                  // eslint-disable-next-line
                  autoFocus
                  onChange={event => setKeywords(event.target.value)}
                  onKeyDown={event => {
                    if (event.keyCode === 38 || event.keyCode === 40) {
                      event.preventDefault()
                    }
                  }}
                />

                {keywords && (
                  <span
                    className="icon clean"
                    onClick={() => {
                      setKeywords("")
                      document.getElementById("search").focus()
                    }}
                    onKeyPress={() => {
                      setKeywords("")
                      document.getElementById("search").focus()
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <CloseIcon />
                  </span>
                )}
              </div>

              <div className="search-results" id="results">
                {results ? (
                  <>
                    {results
                      .slice(0, currentResultPage * resultsPageSize)
                      .map((item, i) => (
                        <SearchItem
                          id={"search-item-" + i}
                          key={i}
                          active={i === cursor}
                          data={item.item}
                        />
                      ))}
                    {results.length > resultsPageSize &&
                      (results.length / resultsPageSize > currentResultPage ? (
                        <div
                          className="more load-more"
                          onClick={() => {
                            setCurrentResultPage(currentResultPage + 1)
                          }}
                          onKeyPress={() => {
                            setCurrentResultPage(currentResultPage + 1)
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          More
                        </div>
                      ) : (
                        <div className="more">Done</div>
                      ))}
                  </>
                ) : (
                  <h5 className="tips">
                    <b>esc</b> to toggle, <b>↑</b> <b>↓</b> to select, <b>↩</b>{" "}
                    to go.
                  </h5>
                )}
              </div>
            </motion.section>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="overlayer"
              onClick={() => {
                setOpen(false)
                setKeywords("")
              }}
            ></motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
)

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15"></line>
    <line x1="9" y1="9" x2="15" y2="15"></line>
  </svg>
)
